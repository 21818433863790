import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  deleteuserAsync,
  getuserbyidAsync,
  getusersAsync,
  signupAsync,
  statuschangeuserAsync,
  updateuserbyidAsync,
} from "../async";

const initialState = {
  userLoader: false,
  userError: "",
  users: [],
  signup: [],
  deleteuser: [],
  statuschange: [],
  getuserbyid: [],
  updateuser: [],
};

export const userSlice = createSlice({
  name: "users",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        getusersAsync.pending,
        signupAsync.pending,
        deleteuserAsync.pending,
        statuschangeuserAsync.pending,
        getuserbyidAsync.pending,
        updateuserbyidAsync.pending
      ),
      (state) => {
        state.userLoader = true;
      }
    );
    builder.addMatcher(isAnyOf(getusersAsync.fulfilled), (state, action) => {
      state.userLoader = false;
      state.users = action.payload.data;
    });
    builder.addMatcher(isAnyOf(signupAsync.fulfilled), (state, action) => {
      state.userLoader = false;
      state.signup = action.payload;
    });
    builder.addMatcher(isAnyOf(deleteuserAsync.fulfilled), (state, action) => {
      state.userLoader = false;
      state.deleteuser = action.payload;
    });
    builder.addMatcher(
      isAnyOf(statuschangeuserAsync.fulfilled),
      (state, action) => {
        state.userLoader = false;
        state.statuschange = action.payload;
      }
    );
    builder.addMatcher(isAnyOf(getuserbyidAsync.fulfilled), (state, action) => {
      state.userLoader = false;
      state.getuserbyid = action.payload.data;
    });
    builder.addMatcher(
      isAnyOf(updateuserbyidAsync.fulfilled),
      (state, action) => {
        state.userLoader = false;
        state.updateuser = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getusersAsync.rejected,
        signupAsync.rejected,
        deleteuserAsync.rejected,
        statuschangeuserAsync.rejected,
        getuserbyidAsync.rejected,
        updateuserbyidAsync.rejected
      ),
      (state, action) => {
        state.userLoader = false;
        state.userError = action.payload.message;
      }
    );
  },
  reducers: {
    emptyuserSlice: (state) => {
      return {
        ...initialState,
      };
    },
  },
});

export const { emptyuserSlice } = userSlice.actions;

export default userSlice.reducer;
