import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const Header = React.lazy(() => import("../Header"));
const Navbar = React.lazy(() => import("../Navbar"));

const Layout = ({ children }) => {
  const location = useLocation();
  const { pathname } = location;
  const [loginpath, setLoginpath] = useState(false);

  useEffect(() => {
    console.log(pathname);
    if (pathname === "/login") {
      setLoginpath(true);
    } else {
      setLoginpath(false);
    }
  }, [pathname]);

  return (
    <>
      {loginpath ? null : (
        <>
          <Header />
          <Navbar />
        </>
      )}
      <Box className="layout">{children}</Box>
    </>
  );
};

export default Layout;
