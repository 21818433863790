import axios from "axios";
import { API_URL, WEB_URL } from "../utils/secret";

/*
 * Axios Api Call Component
 * @type : GET POST PATCH DELETE
 * @api : Api Path
 * @payload : Payload that need to be sent to server
 * @toolkit: dispatch, fulfillWithValue, rejectWithValue
 */
const AxiosClient = async (type, api, payload, toolkit) => {
  const AxiosTypeString = {
    GET: "get",
    POST: "post",
    PUT: "put",
    PATCH: "patch",
    DELETE: "delete",
  };
  return await axios({
    method: AxiosTypeString[type],
    url: `${API_URL}${api}`,
    data: payload,
    headers: {
      "Content-Type": "application/json",
      authorization: "terraviva " + localStorage.getItem("token") || null,
    },
  })
    .then((response) => {
      return toolkit.fulfillWithValue(response.data);
    })
    .catch((error) => {
      console.log(api);
      return toolkit.rejectWithValue(error.response.data);
    });
};
export { AxiosClient };

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.data.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("login");
      window.location.href = `${WEB_URL}/login`;
    }
    return Promise.reject(error);
  }
);