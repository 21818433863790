import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "./axiosclient";

export const getPostinfocountAsync = createAsyncThunk(
  "post/infopostcount",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `/infopostcount`, [], {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

export const getPostAsync = createAsyncThunk(
  "post/getpost",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `/getpost?page=${payload.page}&pagesize=${payload.pagesize}&status=${payload.status}&deadlinetype=${payload.deadlinetype}&keyword=${payload.keyword}&principaloffice=${payload.principaloffice}&category=${payload.category}&deadline=${payload.deadline}&applyanytime=${payload.applyanytime}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const loginAsync = createAsyncThunk(
  "user/login",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `/loginuser`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

export const signupAsync = createAsyncThunk(
  "user/createuser",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `/createuser`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

export const getusersAsync = createAsyncThunk(
  "user/getusers",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `/getinfousers?page=${payload.page}&limit=${payload.limit}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const deleteuserAsync = createAsyncThunk(
  "user/deleteuser",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `/deleteuserbyid/${payload.userid}`, [], {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

export const statuschangeuserAsync = createAsyncThunk(
  "user/statuschangeuser",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `/statuschange/${payload.userid}/${payload.status}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const getuserbyidAsync = createAsyncThunk(
  "user/getuserbyid",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `/getuserbyid/${payload.userid}`, [], {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

export const updateuserbyidAsync = createAsyncThunk(
  "user/updateuserbyid",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `/updateuserbyid`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

export const uploadPostAsync = createAsyncThunk(
  "post/uploadPost",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `/uploadpost`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

export const updatepostAsync = createAsyncThunk(
  "post/updatepost",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `/updatepostbyid`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

export const getpostbyidAsync = createAsyncThunk(
  "post/getpostbyid",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `/getpostbyIdslug/${payload.postid}/${payload.slug}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const poststatusupdateAsync = createAsyncThunk(
  "post/poststatusupdate",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `/poststatusupdate`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

export const postdeleteAsync = createAsyncThunk(
  "post/postdelete",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("DELETE", `/postdelete/${payload.id}`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);
