import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { loginAsync } from "../async";

const initialState = {
  loginLoader: false,
  loginError: "",
  logins: [],
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(loginAsync.pending), (state) => {
      state.loginLoader = true;
    });
    builder.addMatcher(isAnyOf(loginAsync.fulfilled), (state, action) => {
      state.loginLoader = false;
      state.logins = action.payload;
    });

    builder.addMatcher(isAnyOf(loginAsync.rejected), (state, action) => {
      state.loginLoader = false;
      state.loginError = action.payload.message;
    });
  },
  reducers: {
    emptyloginSlice: (state) => {
      return {
        ...initialState,
      };
    },
  },
});

export const { emptyloginSlice } = loginSlice.actions;

export default loginSlice.reducer;
