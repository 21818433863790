import { Box } from "@mui/material";
import React, { Suspense } from "react";
import Layout from "../component/Layout";
import { Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { PrivateRoute } from "./PrivateRoutes";

const LoginPage = React.lazy(() => import("../pages/Login"));
const Dashboard = React.lazy(() => import("../pages/Dashboard"));
const InfoPost = React.lazy(() => import("../pages/Post/InfoPost"));
const WPPost = React.lazy(() => import("../pages/Post/WPPost"));
const AddNewPost = React.lazy(() => import("../pages/Post/AddNewPost"));
const InfoUsers = React.lazy(() => import("../pages/User/Users"));
const WPUsers = React.lazy(() => import("../pages/User/WPUsers"));
const AddNewUser = React.lazy(() => import("../pages/User/AddNewUser"));

const Router = () => {
  return (
    <Suspense
      fallback={
        <Box
          sx={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#ffffff",
          }}
        >
          Loading...
        </Box>
      }
    >
      <Layout>
        <Routes>
          <Route
            path="/"
            element={<PrivateRoute roles={["admin"]} component={Dashboard} />}
          />
          <Route
            path="/post/infopost"
            element={<PrivateRoute roles={["admin"]} component={InfoPost} />}
          />
          <Route
            path="/post/addnewpost"
            element={<PrivateRoute roles={["admin"]} component={AddNewPost} />}
          />
          <Route
            path="/post/addnewpost/:postid/:slug"
            element={<PrivateRoute roles={["admin"]} component={AddNewPost} />}
          />
          <Route
            path="/user/infousers"
            element={<PrivateRoute roles={["admin"]} component={InfoUsers} />}
          />
          <Route
            path="/user/addnewuser"
            element={<PrivateRoute roles={["admin"]} component={AddNewUser} />}
          />
          <Route
            path="/user/updateuser/:id"
            element={<PrivateRoute roles={["admin"]} component={AddNewUser} />}
          />
          {/* <Route path="/" element={<Dashboard />} />
          <Route path="/post/infopost" element={<InfoPost />} />
          <Route path="/post/wppost" element={<WPPost />} />
          <Route path="/post/addnewpost" element={<AddNewPost />} /> */}
          <Route path="/login" element={<LoginPage />} />
          {/* <Route path="/user/infousers" element={<InfoUsers />} />
          <Route path="/user/wpusers" element={<WPUsers />} />
          <Route path="/user/addnewuser" element={<AddNewUser />} /> */}
        </Routes>
      </Layout>
      <Toaster reverseOrder={false} />
    </Suspense>
  );
};

export default Router;
