import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getPostinfocountAsync } from "../async";

const initialState = {
  postcountLoader: false,
  postcountError: "",
  postcount: {},
};

export const postInfoCountSlice = createSlice({
  name: "Info Count",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getPostinfocountAsync.pending), (state) => {
      state.postcountLoader = true;
    });
    builder.addMatcher(
      isAnyOf(getPostinfocountAsync.fulfilled),
      (state, action) => {
        state.postcountLoader = false;
        state.postcount = action.payload.data;
      }
    );

    builder.addMatcher(
      isAnyOf(getPostinfocountAsync.rejected),
      (state, action) => {
        state.postcountLoader = false;
        state.postcountError = action.payload.message;
      }
    );
  },
});

export default postInfoCountSlice.reducer;
