import { configureStore } from "@reduxjs/toolkit";
import postInfoCountSlice from "./slices/getindopostslice";
import InfoPostSlice from "./slices/getpostslice";
import loginSlice from "./slices/login.slice";
import userSlice from "./slices/users.slice";

const store = configureStore({
  reducer: {
    infopostcount: postInfoCountSlice,
    infopost: InfoPostSlice,
    login: loginSlice,
    usersinfo: userSlice,
  },
});

export default store;
