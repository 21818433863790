import { Navigate } from "react-router-dom";
import { useJwt } from "react-jwt";

export const PrivateRoute = ({ component: RouteComponent, roles }) => {
  const token = localStorage.getItem("login");
  const { isExpired } = useJwt(token); // TRUE WHEN EXPIRED

  const userRole = roles.includes("admin");

  if (token && token !== undefined && !isExpired && userRole) {
    return <RouteComponent />;
  }

  if (!isExpired && !userRole) {
    return <Navigate to="/" />;
  }

  return <Navigate to="/login" />;
};
