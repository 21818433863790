import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getPostAsync,
  getpostbyidAsync,
  postdeleteAsync,
  poststatusupdateAsync,
  updatepostAsync,
  uploadPostAsync,
} from "../async";

const initialState = {
  infopostLoader: false,
  infopostError: "",
  infoposts: [],
  uploadpost: [],
  updatepost: [],
  postbyid: [],
  poststatusupdate: [],
  postdelete: []
};

export const InfoPostSlice = createSlice({
  name: "posts",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        getPostAsync.pending,
        uploadPostAsync.pending,
        updatepostAsync.pending,
        getpostbyidAsync.pending,
        poststatusupdateAsync.pending,
        postdeleteAsync.pending
      ),
      (state) => {
        state.infopostLoader = true;
      }
    );
    builder.addMatcher(isAnyOf(getPostAsync.fulfilled), (state, action) => {
      state.infopostLoader = false;
      state.infoposts = action.payload.data;
    });
    builder.addMatcher(isAnyOf(uploadPostAsync.fulfilled), (state, action) => {
      state.infopostLoader = false;
      state.uploadpost = action.payload;
    });
    builder.addMatcher(isAnyOf(updatepostAsync.fulfilled), (state, action) => {
      state.infopostLoader = false;
      state.updatepost = action.payload;
    });
    builder.addMatcher(isAnyOf(getpostbyidAsync.fulfilled), (state, action) => {
      state.infopostLoader = false;
      state.postbyid = action.payload.data;
    });
    builder.addMatcher(isAnyOf(poststatusupdateAsync.fulfilled), (state, action) => {
      state.infopostLoader = false;
      state.poststatusupdate = action.payload;
    });
    builder.addMatcher(isAnyOf(postdeleteAsync.fulfilled), (state, action) => {
      state.infopostLoader = false;
      state.postdelete = action.payload;
    });
    builder.addMatcher(
      isAnyOf(
        getPostAsync.rejected,
        uploadPostAsync.rejected,
        updatepostAsync.rejected,
        getpostbyidAsync.rejected,
        poststatusupdateAsync.rejected,
        postdeleteAsync.rejected
      ),
      (state, action) => {
        state.infopostLoader = false;
        state.infopostError = action.payload.message;
      }
    );
  },
  reducers: {
    emptypostSlice: (state) => {
      return {
        ...initialState,
      };
    },
  },
});

export const { emptypostSlice } = InfoPostSlice.actions;

export default InfoPostSlice.reducer;
